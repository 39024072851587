.PrimaryButton {
  text-transform: capitalize;
  background: #71b8fa;
  border: 2px solid #71b8fa;
  padding: 0.9rem;
  font-weight: 600;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  transition: all 500ms ease;
}

.PrimaryButton:hover {
  background: none;
  color: #71b8fa;
}
