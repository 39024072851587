.Wrapper {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  left: 0;
  background: #fff;
  border: 1px dashed #ccc;
  padding: 1rem 0;
  display: grid;
  gap: 1rem;
}

.Contact,
.NoMatch {
  text-align: left;
  font-size: 0.85rem;
  padding: 0 1rem;
}

.Contact:hover {
  cursor: pointer;
  color: #71b8fa;
}
