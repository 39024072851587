.PrintButton {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #cee6fc;
  transition: all 0.5s ease;
}

.PrintButton svg {
  width: 1.5rem;
  height: auto;
  fill: #1d86e8;
}

.PrintButton:hover {
  background: #71b8fa;
}
