.BookingsWrapper {
  flex: 1 1;
  padding: 0 3rem;
  display: flex;
  overflow: hidden;
}

.Bookings {
  flex: 1;
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
}

.BookingsHeader {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BookingsMain {
  flex: 1;
  border-top: 1px solid #ddd;
  padding: 1rem;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-gap: 1rem;
  overflow: hidden;
}

.BookingsMainMain {
  display: grid;
  grid-template-rows: 1fr auto;
  overflow: hidden;
}

.BookingsMainMain .Table {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.BookingsMainMain .Row {
  grid-template-columns: 2rem 1fr 5rem 10rem 1fr 5rem 5rem;
}

.BookingsMainMain .TableBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.BookingsMainMain .TableField:nth-child(8)::after {
  content: " \00b0 c";
}

.BookingsMainMain .Cell:nth-child(2) {
  display: none;
}

.BookingsMainMain .Cell:nth-child(4) {
  background: rgba(255, 255, 255, 0.4);
  justify-content: center;
  text-align: center;
}

.BookingsMainMain .Cell:nth-child(7),
.BookingsMainMain .Cell:nth-child(8) {
  justify-content: center;
  text-align: center;
}

.TotalsWrapper {
  display: flex;
  justify-content: space-between;
}

.Totals {
  display: grid;
  grid-gap: 10px;
  justify-items: center;
}

.TotalsMain {
  font-size: 2rem;
  font-weight: 600;
}

.Totals:nth-child(1) {
  color: #f90;
}

.Totals:nth-child(2) {
  color: #090;
}

.Totals:nth-child(3) {
  color: #999;
}

.Totals:nth-child(4) {
  color: #f00;
}

.BookingsMainMainFooter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .BookingsWrapper {
    padding: 0 1rem;
  }

  .BookingsWrapper .Totals:last-child {
    display: none;
  }

  .TotalsMain {
    font-size: 1rem;
  }

  .BookingsMainMain .Row {
    grid-template-columns: 1fr 3rem 1fr;
  }

  .BookingsMainMain .Cell:nth-child(1),
  .BookingsMainMain .Cell:nth-child(6),
  .BookingsMainMain .Cell:nth-child(7),
  .BookingsMainMain .Cell:nth-child(8) {
    display: none;
  }

  .Bookings .SearchInputWrapper {
    width: auto;
  }
}

@media print {
  .Bookings {
    background: unset;
  }

  .BookingsHeader {
    display: none;
  }

  .BookingsMain {
    grid-template-columns: 1fr;
    padding: 0;
    margin-top: 1rem;
    border-color: #000;
  }

  .TableHeader {
    border-bottom: 1px solid #000;
    background: unset !important;
    color: #000 !important;
  }

  .Row {
    background: unset !important;
    color: #000 !important;
  }

  aside {
    border-top: 1px solid #000;
    padding-top: 1rem;
  }

  .BookingsMainMain .Cell:nth-child(7) {
    justify-content: flex-start;
    text-align: left;
  }
}
