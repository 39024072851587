.OptionsBar {
  padding: 1rem 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .OptionsBar {
    padding: 1rem;
  }
}

@media print {
  .OptionsBar {
    padding: 0;
  }

  .OptionsBar {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .OptionsBar .SelectWrapper {
    width: auto;
    border: 0;
  }

  .OptionsBar .SelectWrapper .SelectValue {
    padding: 0;
    font-weight: 600;
  }

  .OptionsBar .DateWrapper {
    width: auto;
    border: 0;
  }

  .OptionsBar .DateWrapper::after {
    content: " - ";
    white-space: pre;
  }

  .OptionsBar .DateWrapper .DateValue {
    padding: 0;
  }

  .OptionsBar .SelectWrapper .SelectValue svg,
  .OptionsBar .DateWrapper .DateValue svg {
    display: none;
  }
}
