.Seat {
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 5px;
  cursor: default;
  background: #fff;
  border: 1px dashed #fff;
}

.SeatNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.SeatIcon {
  display: flex;
  justify-content: center;
}

.SeatIcon svg {
  width: 1.5rem;
  height: auto;
}

.AvailableSeat:hover {
  border-color: #000;
}

.NotAvailableSeat .SeatIcon svg {
  fill: #ccc;
}

.NotAvailableSeat .SeatNumber {
  color: #ccc;
}

.ReservedSeat {
  background: #cee6fc;
  border: 1px dashed #cee6fc;
}

.ReservedSeat .SeatIcon svg {
  fill: #1d86e8;
}

.ReservedSeat .SeatNumber {
  color: #1d86e8;
}

.ReservedSeat.AvailableSeat:hover {
  border-color: #1d86e8;
}

.ReservedSeat.NotAvailableSeat .SeatIcon svg {
  fill: #a8d1f7;
}

.ReservedSeat.NotAvailableSeat .SeatNumber {
  color: #a8d1f7;
}

@media only screen and (max-width: 640px) {
  .Seat {
    padding: 0;
  }
}
