.SelectWrapper {
  display: flex;
  align-items: center;
  width: 15rem;
  border: 2px solid #000;
  position: relative;
  box-sizing: border-box;
  cursor: default;
}

.SelectWrapper:hover {
  background: #f1f1f1;
}

.SelectWrapper:hover .ArrowDown {
  fill: #1d86e8;
}

.SelectList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  border: 2px solid #000;
  background: #fff;
  position: absolute;
  left: -2px;
  width: 100%;
  top: calc(100% + 3px);
}

.SelectListItem {
  padding: 10px;
  text-transform: uppercase;
}

.SelectListItem:hover {
  background: #f1f1f1;
}

.SelectValue {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  text-transform: uppercase;
}

.ArrowDown {
  width: 1.5rem;
  height: auto;
  transition: transform 500ms ease;
}

.TransformArrowDown {
  transform: rotate(180deg);
}
