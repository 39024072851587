.Login {
  justify-content: center;
  align-items: center;
}

.Login > div {
  width: 50%;
  box-sizing: border-box;
}

.LoginForm {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 3rem;
}

.LoginHeader {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 0.5rem;
}

.LoginTitle {
  font-size: 3rem;
  font-weight: 200;
}

.LoginSubtitle {
  color: #999;
}

.LoginMain > div {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;
  align-items: flex-end;
}

.ErrorMessage {
  grid-column: span 2;
  color: #f00;
  height: 1rem;
}

#RecaptchaContainer {
  display: none;
}

@media only screen and (max-width: 640px) {
  .Login > div {
    width: auto;
    padding: 1rem;
  }

  .LoginTitle {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 320px) {
  .LoginMain .PrimaryButton {
    width: 8rem;
  }
}
