.Row {
  display: grid;
  grid-gap: 5px;
}

.Cell {
  padding: 5px;
}

.TableHeader {
  background: #71b8fa;
  color: #fff;
  text-transform: uppercase;
}

.TableRow:nth-child(odd) {
  background: #d7e9fa;
}

.TableRow:nth-child(even) {
  background: #cee6fc;
}

.TableRow:hover {
  background: #e4f0fc;
}

.TableData {
  text-transform: capitalize;
  display: flex;
  align-items: center;
}

.Status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.StatusAttended {
  background: #0c0;
}

.StatusConfirmed {
  background: #f90;
}

.StatusCanceled {
  background: #f00;
}

.StatusLabel {
  display: none;
}

@media print {
  .Status {
    display: none;
  }

  .StatusLabel {
    display: flex;
  }
}
