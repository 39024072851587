.BookingDetails {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  left: 100%;
  transition: all 500ms ease;
}

.ShowBookingDetails {
  left: 0;
}

.BookingDetailsWrapper {
  background: #fff;
  box-sizing: border-box;
  display: grid;
  overflow: hidden;
}

.BookingDetailsWrapper > div {
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 2rem;
}

.BookingDetailsDetails {
  position: relative;
  padding: 1rem 2rem !important;
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.BookingSeat {
  display: grid;
  grid-template-rows: auto 1fr;
  border: 1px dashed #ccc;
}

.BookingSeatHeader {
  text-transform: capitalize;
  border-bottom: 1px dashed #ccc;
  padding: 5px;
}

.BookingSeatMain {
  font-size: 2rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BookingStatus {
  display: grid;
  grid-template-rows: auto 1fr;
  border: 1px dashed #ccc;
}

.BookingStatusHeader {
  text-transform: capitalize;
  border-bottom: 1px dashed #ccc;
  padding: 5px;
  text-align: left;
}

.BookingStatusMain {
  padding: 5px;
  display: grid;
  grid-gap: 5px;
}

.StatusOption {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: auto 1fr;
  align-items: center;
  cursor: default;
}

.StatusName {
  text-align: left;
  text-transform: uppercase;
  font-size: 0.8rem;
  color: #555;
}

.StatusIcon {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 2px solid #555;
  display: flex;
  justify-content: center;
  align-items: center;
}

.StatusIconInner {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.StatusOptionAttended .StatusIconInner {
  background: #0c0;
}

.StatusOptionAttended.StatusOptionSelected .StatusIcon {
  border-color: #0c0;
}

.StatusOptionAttended:hover .StatusIcon {
  border-color: #0c0;
}

.StatusOptionConfirmed .StatusIconInner {
  background: #f90;
}

.StatusOptionConfirmed.StatusOptionSelected .StatusIcon {
  border-color: #f90;
}

.StatusOptionConfirmed:hover .StatusIcon {
  border-color: #f90;
}

.StatusOptionCanceled .StatusIconInner {
  background: #f00;
}

.StatusOptionCanceled.StatusOptionSelected .StatusIcon {
  border-color: #f00;
}

.StatusOptionCanceled:hover .StatusIcon {
  border-color: #f00;
}

.BackIconWrapper {
  justify-content: flex-start !important;
  align-items: center;
  padding: 1rem 2rem !important;
  background: #f3f3f3;
  position: relative;
}

.BackIconWrapper svg {
  fill: #ccc;
  width: 3rem;
  height: auto;
  position: absolute;
  transition: all 500ms ease;
}

.BackIconWrapper svg:hover {
  fill: #71b8fa;
}

.BookingDetailsHeader {
  padding: 1rem;
  flex: 1;
  font-size: 1.5rem;
}

.BookingDetailsMain {
  background: #f3f3f3;
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 5px;
}

.BookingDetailsMain div:last-child {
  grid-column: span 2;
}

.BookingDetailsMain div:last-child .TextInputLabel {
  text-align: center;
}

.TemperatureInput {
  grid-column: span 1 !important;
}

.TemperatureInput .TextInputLabel,
.TemperatureInput input {
  text-align: left !important;
}

.TemperatureInput .TextInputLabel::after {
  content: " \00b0 c";
}

.BookingDetailsMain div:last-child input {
  text-align: center;
}

@media only screen and (max-width: 640px) {
  .BookingDetailsWrapper {
    width: calc(100% - 2rem);
  }

  .BookingDetailsWrapper > div {
    padding: 1rem !important;
  }
}
