.SearchInputWrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
  width: 15rem;
  background: #fff;
  border: 1px solid #ccc;
  box-sizing: border-box;
  cursor: default;
  padding: 10px;
}

.SearchIcon {
  width: 1.5rem;
  height: auto;
  fill: #ccc;
}

.SearchInput {
  padding: 0;
}
