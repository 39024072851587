.BookingSuccess {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: #f1fff8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  left: 100%;
  font-size: 1rem;
  font-weight: 400;
  transition: all 500ms ease;
}

.BookingSuccessIcon {
  fill: #1de883;
  width: 1.5rem;
  height: auto;
}

.BookingSuccessMessage {
  color: #666;
}

.BookingSuccessNumber {
  color: #faa3a3;
}

.ShowBookingSuccess {
  left: 0;
}
