.ContactsWrapper {
  flex: 1 1;
  padding: 3rem 3rem 0;
  display: flex;
  overflow: hidden;
}

.Contacts {
  flex: 1;
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
}

.ContactsHeader {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ContactsMain {
  flex: 1;
  border-top: 1px solid #ddd;
  padding: 1rem;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-gap: 1rem;
  overflow: hidden;
}

.ContactsMainMain {
  display: grid;
  grid-template-rows: 1fr auto;
  overflow: hidden;
}

.ContactsMainMain .Table {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.ContactsMainMain .Row {
  grid-template-columns: 3rem 1fr 1fr 1fr;
}

.ContactsMainMain .TableBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.ContactsMainMain .Cell:nth-child(3) {
  background: rgba(255, 255, 255, 0.4);
}

.TotalsWrapper {
  display: flex;
  justify-content: space-between;
}

.Totals {
  display: grid;
  grid-gap: 10px;
  justify-items: center;
}

.TotalsMain {
  font-size: 2rem;
  font-weight: 600;
}

.Totals:nth-child(1) {
  color: #f90;
}

.Totals:nth-child(2) {
  color: #090;
}

.Totals:nth-child(3) {
  color: #999;
}

.Totals:nth-child(4) {
  color: #f00;
}

.ContactsMainMainFooter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .TotalsMain {
    font-size: 1rem;
  }

  .ContactsWrapper {
    padding: 1rem 1rem 0;
  }

  .ContactsMainMain .Row {
    grid-template-columns: 1fr 1fr;
  }

  .ContactsMainMain .Cell:nth-child(1),
  .ContactsMainMain .Cell:nth-child(4) {
    display: none;
  }

  .ContactsMainMain .Cell:nth-child(3) {
    background: inherit;
  }

  .Contacts .SearchInputWrapper {
    width: auto;
  }
}

@media print {
  .Contacts {
    background: unset;
  }

  .ContactsHeader {
    display: none;
  }

  .ContactsMain {
    grid-template-columns: 1fr;
    padding: 0;
    margin-top: 1rem;
    border-color: #000;
  }

  .TableHeader {
    border-bottom: 1px solid #000;
    background: unset !important;
    color: #000 !important;
  }

  .Row {
    background: unset !important;
    color: #000 !important;
  }

  aside {
    border-top: 1px solid #000;
    padding-top: 1rem;
  }
}
