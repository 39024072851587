.TelInputWrapper {
  display: grid;
  grid-template-rows: 1.5rem 1fr;
  grid-gap: 0.5rem;
}

.TelInputLabel {
  padding: 0 1rem;
  text-transform: capitalize;
  text-align: left;
  overflow: hidden;
}

.TelInput {
  background: #f9f8f8;
  border: 1px solid #ccc;
  padding: 1rem;
  width: 100%;
}

.TelInputNotValid {
  border-color: #f00;
}
