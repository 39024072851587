.App {
  display: flex;
  height: 100vh;
  overflow: hidden;
  position: relative;
  flex-direction: column;
}

header {
  padding: 1rem 3rem;
  background: #cee6fc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Logo {
  color: #000;
  text-decoration: none;
}

.Logo:hover {
  text-decoration: underline;
}

nav {
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: column;
}

nav .Link {
  text-transform: capitalize;
  color: #1d86e8;
}

nav .ActiveLink {
  color: #f90;
}

.Logout {
  text-decoration: underline;
  cursor: pointer;
}

.Logout:hover {
  text-decoration: none;
}

.UserName {
  text-transform: capitalize;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.View {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

footer {
  padding: 1rem 3rem;
  text-align: center;
  font-size: 0.8rem;
  color: #555;
}

@media only screen and (max-width: 640px) {
  header,
  footer {
    padding: 1rem;
  }
}

@media print {
  header {
    background: unset;
    margin: 1rem 0;
  }

  nav {
    display: none;
  }

  header {
    padding: 0;
  }
}
