.LayoutWrapper {
  flex: 1;
  padding: 0 3rem;
  display: flex;
  overflow: hidden;
}

.Layout {
  flex: 1;
  background: #f1f1f1;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-gap: 1rem;
  padding: 1rem;
  overflow: scroll;
}

@media only screen and (max-width: 640px) {
  .LayoutWrapper {
    padding: 0 1rem;
  }

  .Layout {
    grid-gap: 5px;
    padding: 5px;
  }
}

@media only screen and (max-width: 320px), screen and (max-height: 734px) {
  .Layout {
    grid-gap: 3px;
    padding: 3px;
  }
}

@media print {
  .LayoutWrapper {
    padding: 0;
  }
}
