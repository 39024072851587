.SpinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Spinner {
  border: 2px solid #cee6fc;
  border-top: 2px solid #71b8fa;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  animation: spin 600ms linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
