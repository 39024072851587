.BookingFailure {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: #ffeeee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  left: 100%;
  font-size: 1rem;
  font-weight: 400;
  transition: all 500ms ease;
}

.BookingFailureIcon {
  fill: #f00;
  width: 1.5rem;
  height: auto;
}

.BookingFailureReason {
  color: #666;
}

.BookingFailureNumber {
  color: #f00;
}

.ShowBookingFailure {
  left: 0;
}
